// CookieBanner.js

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieBanner = () => {
  const [isBannerVisible, setBannerVisible] = useState(true);

  useEffect(() => {
    // Check if the user has previously accepted cookies
    const hasAcceptedCookies = Cookies.get('accept-cookies');

    if (hasAcceptedCookies) {
      setBannerVisible(false);
    }
  }, []);

  const acceptCookies = () => {
    // Set a cookie to remember user's preference
    Cookies.set('accept-cookies', 'true', { expires: 365 }); // Expires in 365 days

    // Hide the banner
    setBannerVisible(false);
  };

  return (
    <>
      {isBannerVisible && (
        <div className="cookie-banner">
          <p>This website uses cookies to improve your experience.</p>
          <button onClick={acceptCookies}>Accept Cookies</button>
        </div>
      )}
    </>
  );
};

export default CookieBanner;