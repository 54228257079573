import Mock from "../mock";

const database = {
  information: {
    name: 'Alessandro Berruti',
    aboutContent: "I'm a electronic engineer. I'm specialized in embedded system, I can help you in the definition of system architecture, hardware design and firmware development.",
    age: 32,
    phone: '',
    nationality: 'Italian',
    language: 'Italian, English',
    email: 'berruti.alessandro@gmail.com',
    address: 'Rivoli, Torino',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/aleberruti/',
      dribbble: '',
      github: ''
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/berruti_cv.pdf'
  },
  services: [
    {
      title: "Electronic system architecture",
      icon: 'rocket',
      details: "I design the behind-the-scenes of cool tech, making sure everything fits together smoothly. From idea to reality, I create reliable systems that set the stage for top-notch tech."
    },
    {
      title: "Electronic design",
      icon: 'construction-hammer',
      details: " I sketch out circuits and components, turning ideas into tangible products. From concept to creation, I specialize in crafting electronics that simply work, no frills, just solid design."
    },
    {
      title: "Firmware development",
      icon: 'code',
      details: " I code the brains behind the hardware. I create reliable and efficient firmware that brings electronic systems to life. No tech jargon, just solid coding for seamless functionality."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "E-CAD",
      value: 0
    },
    {
      title: "C/C++",
      value: 0
    },
    {
      title: "MCU platform 8,16,32 bits",
      value: 0
    },
    {
      title: "Linux embedded",
      value: 0
    },
    {
      title: "MPU systems",
      value: 0
    },
    {
      title: "Matlab/Simulink",
      value: 0
    },
    {
      title: "High speed digital interfaces",
      value: 0
    },
    {
      title: "Python and scripting",
      value: 0
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Welcome",
      subtitle: "Coming soon",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: '#'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Jan 2023 - Present",
        position: "Electronic engineer",
        company: "Freelance",
        details: "I helped my clients in the definition of system requirements, architectural design and development of the electronic system."
      },
      {
        id: 2,
        year: "Jan 2023 - Present",
        position: "CEO and Co-Founder",
        company: "Enercade srl",
        details: "At Enercade, we are developing a cutting-edge digital platform that enables efficient management of shared energy within Renewable Energy Communities. Our startup is driven by the goal of maximizing the economic incentives provided by the Italian government while simultaneously promoting awareness, engagement, and accountability. More information can be found in the <a href='https://enercade.com/' target='_blank'>website</a>."
      },
      {
        id: 3,
        year: "Aug 2022 - Dec 2023",
        position: "Engineering leader",
        company: "TUC technology",
        details: "As the engineering leader at TUC, I successfully led and coordinated the entire engineering team in the delivery of the inaugural version of the TUC plug.  <a href='https://www.youtube.com/watch?v=6G-8Q8MGX5g&t=133s&ab_channel=TUCtechnology' target='_blank'>Watch here the video</a>."
      },
      {
        id: 4,
        year: "Jan 2022 - Aug 2022",
        position: "System and project engineer",
        company: "TUC technology",
        details: "Managed strategic decisions for electronic systems, hardware, and firmware. Led the charge in defining system and networking architecture, ensuring alignment with project goals. Hands-on testing enthusiast and key liaison with test labs and suppliers. Successfully headed a team overseeing hardware and firmware development. Specialized in Ethernet switches, PHYs, common busses, and tackled challenges like electromagnetic compatibility and signal integrity."
      },
      {
        id: 5,
        year: "Oct 2020 - Jan 2022",
        position: "Hardware lead and project manager",
        company: "EMA global engieerng srl",
        details: "Handled hardware selection, schematics, and risk analysis for automotive projects. Bridged gaps between teams, led projects like sports car seatbelt controls, HVAC systems, and luxury car lighting."
      },
      {
        id: 6,
        year: "Apr 2017 - Oct 2020",
        position: "Firmware and hardware engineer",
        company: "EMA srl",
        details: "Applied model-based design for an automated car seat system and simulated IoT node behavior using LoRa for energy efficiency. Collaborated on hardware and software for a low-power IoT node in domestic energy management. Designed a safety-critical gear selection buttons panel and implemented haptic feedback for lights management. Prototyped an air quality management system for a hands-on approach to diverse projects."
      },
      {
        id: 7,
        year: "Jan 2015 - Present",
        position: "System integrator and project manager",
        company: "Alsiber.it srl",
        details: "Contribute to the design of mechatronic systems for diverse applications, from robotized firefighting in industrial and automotive sectors to software design projects such as turnstile access control for banks, gates automation, and fire extinguisher systems in chemical plants."
      },
      {
        id: 8,
        year: "Dec 2015 - Present",
        position: "Teacher and Engineering area leader",
        company: "Associazione Scientifica L. Lagrange",
        details: "Strategized annual STEM and Robotics class activities, overseeing planning and teaching in winter and summer sessions. Covered a range of topics including robotics, electronics, coding, and machine learning for an interactive learning environment."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2015 - 2018",
        graduation: "Master’s Degree - Electronic Engineering",
        university: "Politecnico di Torino",
        details: "108/110"
      },
      {
        id: 2,
        year: "2011 - 2015",
        graduation: "Bachelor’s Degree - Electronic Engineering",
        university: "Università degli studi di Pavia",
        details: "97/110"
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Coming soon!',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: "October",
      createYear: "2023"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+39 3454670106'],
    emailAddress: ['berruti.alessandro@gmail.com'],
    address: "Rivoli, Torino"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});